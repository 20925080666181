import React from 'react';
import PropTypes from 'prop-types';
//components
import { Button } from 'devextreme-react/button';

import styles from './style.module.scss';

export const CompanieOfferItem = (props) => {
  const {
    offer = {},
    franchise = 0,
    setCompanieOffer,
  } = props;
  const { image = '', name = '', tariffs = [], description = '' } = offer;
  let price = 0;
  tariffs?.forEach((item) => {
    if (item?.franchise === franchise) price = item?.price;
  });
  return (<>
    <div className={styles?.item}>
      <div>
        <img src={image?.path} />
        <div>{name}</div>
        <div>
          Франшиза: {franchise}
        </div>
        <div className={styles?.sum}>
          Сума: <span>{price} грн.</span>
        </div>
        <div dangerouslySetInnerHTML={ { __html: description } } ></div>
      </div>
      <Button onClick={() => setCompanieOffer({
        ...offer, selectedFranchise: franchise, selectedPrice: price,
      })} text = "Придбати"/>
    </div>
  </>);
};

CompanieOfferItem.propTypes = {
  setCompanieOffer: PropTypes.func.isRequired,
  image: PropTypes.any,
  name: PropTypes.any,
  tariffs: PropTypes.any,
  price: PropTypes.any,
  description: PropTypes.any,
  franchise: PropTypes.number,
  offer: PropTypes.object,
};

export default CompanieOfferItem;
