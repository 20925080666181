import { gql } from '@apollo/client';
import { gqlClient } from 'gql-client';
import CustomStore from 'devextreme/data/custom_store';

const gQuery = gql(
  'query getLab($labNumbers:[Int], $jfilt: [JSON] ) { getLab(all:true, jfilt: $jfilt,labNumbers:$labNumbers)}'
);

let labs = [];

const loadLabs = async (options) => {
  if (!labs.length) {
    const resp = await gqlClient.query({ query: gQuery });
    labs = resp?.data?.getLab || [];
  }
  const { searchExpr, searchValue } = options || {};
  if (searchValue) {
    return {
      data: labs.filter((r) => r[searchExpr].includes(searchValue)),
    };
  }
  return { data: labs };
};

const getByKey = async (key) => {
  if (!labs.length) await loadLabs();
  return labs.find((r) => r.ref === key);
};

export const dsLabs = new CustomStore({
  key: 'ref',
  byKey: getByKey,
  load: loadLabs,
});
