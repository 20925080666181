import { loader } from 'graphql.macro';
import { calculateStatus } from 'utils/calculate-order-status.js';
import { createDataSource } from 'utils/gql-datasource.js';
const dsGetOrders = loader('./dsGetOrders.gql');

export const dsOrders = createDataSource(dsGetOrders, {
  processData: (data) => (
    data?.map((row) => ({ ...row, status: calculateStatus(row) }))
  ),
});

dsOrders.on('loading', (options) => {
  options.userData = dsOrders?.userData;
});

