import { loader } from 'graphql.macro';
import CustomStore from 'devextreme/data/custom_store';
import { gqlClient } from 'gql-client.js';
import { DEFAULT_PROJ_PNAME } from 'app-constants.js';
const dsGetProj = loader('./dsGetProj.gql');

const getProj = () => gqlClient
  .query({ query: dsGetProj, fetchPolicy: 'cache-first' })
  .then((res) => res.data.list);

export const dsProj = new CustomStore({
  key: 'ref',
  load: () => getProj(),
  byKey: (ref) => getProj().then((res) => res.filter((p) => p.ref === ref)),
});

export class Projects {

  constructor(initArray, lab) {
    this.items = initArray ?? [];
    const labProjs = lab?.projs?.map((p) => p.direction) ?? [];
    this.available = this.items.filter((p) => labProjs.includes(p.ref)) ?? [];
    const defProject = this.byName(DEFAULT_PROJ_PNAME);
    this.default = this.available.find((p) => p.ref === defProject.ref) ?? this.available?.[0];
    //-- predefined projects
    this.sert = this.refById('sert');
    this.gbo = this.refById('gbo');
    this.otk = this.refById('otk');
    this.kl = this.refById('kl');
  }

  nameByRef(ref) {
    return this.items.find((p) => p.ref === ref)?.name;
  }

  byName(name) {
    return this.items.find((p) => p.predefined_name === name);
  }

  refById(id) {
    return this.items.find((p) => p.id === id)?.ref;
  }

}
