import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
//components
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { TextLine } from 'pages/ep-main/components/text-line';

import { getCompaniesOffers } from '../../api/requests';
import { CompanieOfferItem } from './companie_offer_item';
import { checkVehicleTypeCDE, filterByFranchise } from '../utils';
import styles from './style.module.scss';

export const CompaniesOffers = (props) => {
  const {
    setData = () => {},
    data = {},
  } = props;
  const { franchise, privilege, period } = data;
  const [ companiesOffers, setCompaniesOffers ] = useState([]);
  const [ filteredCompaniesOffers, setFilteredCompaniesOffers ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const handleSetConpanieOffer = (data) => {
    setData((prev) => ({ ...prev, step: 3, companie: { ...data } }));
  };
  const requestOptins = {
    vehicle_type: data?.vehicle_type || '',
    vehicle_city_id: data?.vehicle_city_id || '',
    privilege,
    period,
  };
  if (checkVehicleTypeCDE(data?.vehicle_type) && data?.next_service_date) {
    requestOptins.next_service_date =
    dayjs(data?.next_service_date).format('YYYY-MM-DD');
  }
  if (checkVehicleTypeCDE(data?.vehicle_type) && data?.start_date) {
    requestOptins.start_date =
    dayjs(data?.start_date).format('YYYY-MM-DD HH:mm:ss');
  }
  if (checkVehicleTypeCDE(data?.vehicle_type) && period) {
    requestOptins.period = period;
  }
  if (data?.vehicle_engine_volume) {
    requestOptins.vehicle_engine_volume = data?.vehicle_engine_volume;
  }
  useEffect(() => {
    setLoading(true);
    getCompaniesOffers(requestOptins)?.then((offers) => {
      setCompaniesOffers(offers);
      setFilteredCompaniesOffers(filterByFranchise(offers, franchise));
      setLoading(false);
    }, () => {
      setLoading(false);
    });
  }, [ privilege, period ]);

  useEffect(() => {
    setFilteredCompaniesOffers(filterByFranchise(companiesOffers, franchise));
  }, [ franchise ]);
  return (<>
    <TextLine text="Список предложений" />
    <div className={styles?.container}>
      {loading ?
        <LoadIndicator
          className={styles?.res_loader}
          indicatorSrc='img/loader.gif'
          width={50}
          height={50}
        /> :
        filteredCompaniesOffers?.length > 0 ?
          filteredCompaniesOffers?.map((offer, index) => (
            <CompanieOfferItem
              key={index}
              offer={offer}
              franchise={franchise}
              setCompanieOffer={handleSetConpanieOffer}
            />),
          ) : 'Пропозицій не знайдено'}
    </div>
    <TextLine />
  </>);
};

CompaniesOffers.propTypes = {
  setData: PropTypes.func,
  data: PropTypes.object,
};

export default CompaniesOffers;
