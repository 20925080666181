import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Toolbar, Item } from 'devextreme-react/toolbar';
import { DataGrid } from 'devextreme-react';
import { Editing, Texts, Column, Scrolling } from 'devextreme-react/data-grid';
import { loader } from 'graphql.macro';
import { gqlClient } from 'gql-client';
import { useDate, useLab, useTraceUpdate } from 'hooks';
import { CustomRule } from 'devextreme-react/validator';
import { custom } from 'devextreme/ui/dialog';
import { showWarning } from 'utils/notify';
import dayjs from 'dayjs';
import { useAuth } from 'contexts/auth';
const getWorkersFrom1C = loader('./gql/getWorkersFrom1C.gql');

export const WorkersGrid = (props) => {
  // console.log(props)
  const [ data, setData ] = useState([]);
  const [ dataGraph, setDataGraph ] = useState(new Map());
  const [ docReadOnly ] = useState(false);
  const { options } = useAuth();
  const { lab } = useLab();
  const { formatDate, startOfMonth } = useDate();
  const [ protectDate, setProtectDate ] = useState(startOfMonth());

  const servicesDataGridRef = useRef();
  const focusedRowKey = useRef(null);

  const legend = props.legend;
  props.gData.current = data;

  const handleRowUpdating = () => {};

  const fillValidatorGraph = useCallback(() => {
    gqlClient.query({
      query: getWorkersFrom1C,
      variables: { period_reg: formatDate(props.data.period_reg) },
    }).then((resp) => {
      const workers = resp?.data?.worktime?.workers || [];
      if (workers.length) {
        const workerGraphMap = new Map();
        workers.forEach((wrow) => {
          const hoursGraphMap = new Map();
          wrow.hours.forEach((hrow) => {
            hoursGraphMap.set(`d_${hrow.day}`, hrow.hoursgraph);
          });
          workerGraphMap.set(wrow.person_id, hoursGraphMap);
        });
        setDataGraph(workerGraphMap);
      }
    },
    );
  }, [formatDate, props.data.period_reg]);

  useEffect(() => {
    if (lab) {
        const gridOptions = options.closeByDocs?.grid.find((r) => r.docType === 'doc.work_time');
        const date = gridOptions?.byOrgs.find((r) => r.organization === lab.organization)?.date;
        if (date > startOfMonth(props.data.period_reg)) {
          setProtectDate(date);
        } else {
          setProtectDate(startOfMonth());
        }
    };
  }, [lab, options, props.data.period_reg, startOfMonth]);

  useEffect(() => {
    const worktable = [];
    const days = dayjs(props.data.period_reg).endOf('month').date();
    props.data?.worktable?.forEach((row) => {
      const obj = { ...row };
      if (row?.hours?.length > 0) {
        obj.hours = row.hours.filter((r) => r.day <= days);
        obj.hours.forEach((hrow) => {
          obj[`d_${hrow.day}`] = hrow.hours;
        });
      }
      worktable.push(obj);
    });
    if (worktable.length) {
      fillValidatorGraph();
    }
    setData(worktable);
  }, [ props.data, fillValidatorGraph ]);

  const fillGrid = (e) => gqlClient
    .query({
      query: getWorkersFrom1C,
      variables: { period_reg: formatDate(props.data.period_reg) },
    })
    .then((response) => {
      const workers = response?.data?.worktime?.workers || [];
      if (workers.length) {
        workers.forEach((wrow) => {
          wrow.hours.forEach((hrow) => {
            wrow[`d_${hrow.day}`] = hrow.hours;
          });
        });

        if (e?.element.id === 'refresh') {
          const filtered = workers.filter((r) => !data.find(
            (wtr) => wtr.person_id === r.person_id));

          let newData = data.slice();
          newData.forEach((w) => {
            const workerForUpdate = workers
              .find((wu) => wu.person_id === w.person_id,
              );
            if (workerForUpdate) {
              w.graphic_id = workerForUpdate.graphic_id;
              w.person_fio = workerForUpdate.person_fio;
              w.graphic_name = workerForUpdate.graphic_name;
              w.positions = workerForUpdate.positions;
            }
          });

          newData = [ ...newData, ...filtered ];

          setData(newData);
          props.gData.current = newData;
          return;
        }
      }
      if (!workers.length) {
        showWarning('Відсутні прийняті в лабораторію працівники');
      }
      setData(workers);
      fillValidatorGraph();
      props.gData.current = workers;
    });

  const fillButtonOptions = {
    text: 'Заповнити',
    icon: 'download',
    onClick: (e) => {
      if (data.length) {
        const myDialog = custom({
          title: 'Таблиця заповнена.',
          messageHtml: `
            <p style="text-align:center;">
            <b>Заповнення перезапише усі дані<br/>
            Продовжити?
            </b></p>`,
          buttons: [
            { text: 'Так', onClick: () => 1 },
            { text: 'Ні', onClick: () => 2 },
          ],
        });
        myDialog.show().then((dialogResult) => {
          if (dialogResult === 1) {
            fillGrid(e);
          }
        });
      } else {
        fillGrid(e);
      }
    },
    hint: 'Заповнити за даними обліку',
  };
  const refreshButtonOptions = {
    text: 'Обновити',
    elementAttr: { id: 'refresh' },
    icon: 'import',
    onClick: (e) => fillGrid(e),
    hint: 'Оновити перелік працівників',
  };

  const isSunday = (day) => dayjs(props.data.period_reg)
    .startOf('month').add(day - 1, 'day').day() === 0;

  const dayCellHeader = (day) => {
    if (isSunday(day)) {
      return <div style={{ color: 'red' }}>{day}</div>;
    }
    return <div>{day}</div>;
  };

  const validatorHours = useCallback((e) => {
    const {
      data: { person_id },
      column: { dataField },
      rule,
      value,
    } = e;
    const personLimit = dataGraph.get(person_id)?.get(dataField) || 0;
    const limit = (startOfMonth(props.data.period_reg) >= '2023-04-01' ? personLimit : 8);
    rule.message = `Максимум: ${limit}`;
    return ((limit >= value) || legend.find((r) => r.value === value));
  }, [dataGraph, legend, props.data.period_reg, startOfMonth]);


  useTraceUpdate({startOfMonth, formatDate})
  return (
    <>
      <Toolbar className='otk-dg-toolbar-border dx-theme-border-color'>
        <Item
          location='before'
          locateInMenu='auto'
          widget='dxButton'
          disabled={docReadOnly || data.length}
          options={fillButtonOptions}
        />
        <Item
          location='before'
          locateInMenu='auto'
          widget='dxButton'
          disabled={docReadOnly || !data.length}
          options={refreshButtonOptions}
        />
      </Toolbar>
      <DataGrid
        ref={servicesDataGridRef}
        noDataText='Список порожній'
        remoteOperations={false}
        rowAlternationEnabled
        showBorders
        showColumnLines
        allowColumnResizing
        selectTextOnEditStart
        hoverStateEnabled
        columnResizingMode='widget'
        columnAutoWidth
        keyExpr={'person_id'}
        dataSource={data}
        focusedRowEnabled={!docReadOnly}
        errorRowEnabled={false}
        onCellPrepared={(e) => {
          if (e.column?.name?.startsWith('d_') &&
          !e.column.allowEditing) {
            e.cellElement.style.background = 'lightgrey';
          }
        }}
        onContextMenuPreparing={(e) => {
          if (e.column?.name.startsWith('d_')) {
            if (e.row.rowType === 'header' || !e.column.allowEditing) {
              e.items = [ ];
              return;
            }
            e.items = legend.map((r) => (
              { text: `${r.value} - ${r.caption}`,
                onItemClick: () => {
                  e.row.data[e.column.name] = r.value;
                  servicesDataGridRef.current.instance.refresh(true);
                },
              }));
          }
        }}
        onRowUpdating={handleRowUpdating}
        onFocusedRowKeyChange={(rowKey) => {
          focusedRowKey.current = rowKey;
        }}>
        <Editing
          mode='cell'
          allowAdding={false}
          allowUpdating={true}
          allowDeleting={false}
          useIcons={true}
          confirmDelete={true}>
          <Texts confirmDeleteMessage='Вилучити?' deleteRow='вилучити' />
        </Editing>
        <Scrolling
          showScrollbar='always'
          useNative={true}
        />
        <Column
          dataField='person_fio'
          caption='Працівник'
          allowEditing={false}
        />
        <Column
          dataField='graphic_name'
          caption='Графік'
          allowEditing={false}
          width={230}
          maxWidth={70}
        />
        <Column
          dataField='positions'
          caption='Ставок'
          width={65}
          allowEditing={false}
        />

        {data.length > 0 &&
          data[0]?.hours?.map((rec) => (
            <Column
              key={rec.day}
              alignment='center'
              dataField={`d_${rec.day}`}
              caption={rec.day}
              allowEditing={!isSunday(rec.day) &&
                (rec.day >= dayjs(protectDate).get('date')) &&
                (dayjs().get('date') >= rec.day ||
                startOfMonth() > startOfMonth(props.data.period_reg))
              }
              allowSorting={false}
              headerCellRender={() => dayCellHeader(rec.day)}
              width={50}
              dataType='string'>
              <CustomRule
                reevaluate={true}
                ignoreEmptyValue={true}
                validationCallback={validatorHours}
              />
            </Column>
          ))}
      </DataGrid>
    </>
  );
};

WorkersGrid.propTypes = {
  data: PropTypes.object,
  gData: PropTypes.any,
  legend: PropTypes.arrayOf(PropTypes.object),
};
