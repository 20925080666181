import React, { useNavigate } from 'react-router-dom';
import { useProj } from 'hooks';
import Button from 'devextreme-react/button';
import PropTypes from 'prop-types';

import styles from './epBtn.module.scss';

export const EpSimpleBtn = ({ _id, _key = 0, type_EP_ref, item }) => {
  const navigate = useNavigate();
  const { proj } = useProj();
  const type = proj?.find((p) => p.ref === type_EP_ref);
  const getPath = (typeEP) => {
    let path = '';
    switch (typeEP) {
    case 'отк':
      path = '/ep';
      break;
    case 'тахо':
      path = '/ep-taho';
      break;
    default:
      break;
    }
    return path;
  };
  const handleClick = () => {
    const path = getPath(type?.predefined_name);
    (_id !== 'new' && path) &&
     
    navigate(`${path}/new?order_ref=${_id}&s=${_key}&item_ref=${item.ref}&item_name=${item.name}`);
  };
  return (
    <div className={styles?.wrapBtn}>
      <Button onClick={handleClick}
        stylingMode= "contained" text="створити ЕП" disabled={_id === 'new'}
        elementAttr={{ title:
          'створити новий Електроний протокол на основі цього замовлення' }}/>
    </div>
  );
};

EpSimpleBtn.propTypes = {
  _id: PropTypes.string,
  type_EP_ref: PropTypes.string,
  _key: PropTypes.number,
  item: PropTypes.object,
};

export default EpSimpleBtn;
