// import { version } from '../package.json';
const { version } = require('../package.json');

export const appInfo = {
  title: 'ОТК-СЕРВІС Кабінет лабораторії 2.0',
  docTitle: 'Кабінет',
  version: String(version ?? ''),
};


