import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import {
  Menu,
  ScrollView,
  Button,
} from 'devextreme-react';

import styles from '../styles/style.module.scss';


import {
  DocInfoBar,
  StickyBar,
} from 'components';

import { TextItem } from './textItem';
import { cardFields1, cardFields2 } from './fields';
import { getPayment, getPolicy } from './getPolicy';
import { confirmPolicyPay, savePolicyData } from '../new-policy/utils';
import { initApi } from './../api/requests';
import { useAuth } from 'contexts/auth';
import { loader } from 'graphql.macro';
import Part4 from '../new-policy/part4';
import { useDate } from 'hooks';
const getAssurance = loader('../gql/getAssurance.graphql');

export const PolicyCard = () => {

  const { options } = useAuth();
  initApi(options.assurance);

  const navigate = useNavigate();
  const gqlClient = useApolloClient();

  const { id } = useParams();
  const isNewDoc = id === 'new';

  const [ loading, setLodading ] = useState(false);
  const [ data, setData ] = useState({});
  const [ fulldata, setFullData ] = useState({});
  const [ resData, setResData ] = useState({});
  const { displayDateTime } = useDate();

  useEffect( () => {
    setLodading(true);

    getPolicy({ ref: id, gqlClient })
    .then((getData) => {
      setData(getData);
      setLodading(false);

      gqlClient.query({
        query: getAssurance,
        variables: { ref: id },
      })
        .then((response) => {
          const res = response?.data?.getAssurance?.[0];
          res.body.payment_type = res.body.paymentType;
          setFullData(res.body);
          setResData(res);
      });
    });

  }, [ id ]);

  return (
    <div>
      <StickyBar>
        <DocInfoBar
          name={`Страховий поліс №${data?.policy_number || ''}`}
          loading={loading}
          isNew={isNewDoc}
        >
        </DocInfoBar>
        <Menu
          onItemClick={(e) => {
            if (e.itemData.id === 'close') {
              navigate('/policy');
            }
          }}
          dataSource={[
            {
              text: 'Закрити',
              id: 'close',
              icon: 'close',
            },
          ]}
        />
      </StickyBar>
      <div className={`${styles?.ep_main_content_block} dx-card`}>
        <ScrollView>
          <div className={styles?.policy_card_wrap}>
            <div>
              {cardFields1.map((item, index) => <TextItem key={index}
                text={item?.text} data={data?.[item?.data]}/>,
              )}
            </div>
            <div>
              {cardFields2.map((item, index) => <TextItem key={index}
                text={item?.text} data={data?.[item?.data]}/>,
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <a href={data?.policy_confirm?.policy_direct_link}
                target='_blank' rel="noreferrer">
                <Button icon='edit' text='Перевірити поліс' />
              </a>
              {data?.policy_status !== 'confirmed' &&
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '15px 0 15px 0',
                // paddingBottom: '15px',
                }}>
                <Button
                  visible={data?.policy_status !== 'confirmed'}
                  text='Перевірити оплату'
                  type='danger'
                  onClick={() => {
                    getPayment({ ref: data?.ref, gqlClient })
                      .then(async (res) => {
                        if (res) {
                          setLodading(true);
                          setData((prev) => ({ ...prev,
                            policy_easyPay: res,
                          }));
                          data.policy_easyPay = res;
                          await confirmPolicyPay({ setData, data });
                          await savePolicyData({ gqlClient, data });
                          setLodading(false);
                        }
                      });
                  }}
                />
                <Button
                  text='До оплати'
                  onClick={() => {
                    setFullData((prev) => ({
                      ...prev,
                      step: 4,
                    }));
                  }}
                />
              </div>
              }
              {data?.policy_status === 'confirmed' &&
                  <Button text='Сплачено' type='success' />
              }
              <div style={{ display: 'flex', flexDirection: 'column', width: '300px' }} >
              <TextItem text='Дата продажу' data={displayDateTime(resData?.date)} width={100} />
              <TextItem text='Лабораторія' data={resData?.department?.name} width={100} />
              </div>

            </div>
          </div>
        </ScrollView>
        {fulldata.step === 4 &&
          <div className={`${styles?.container} dx-card`} >
            <Part4 data={fulldata} setData={setFullData}/>
          </div>
        }
      </div>
    </div>
  );
};
