import React, { useEffect, useRef, useState } from 'react';
import { loader } from 'graphql.macro';
import Scheduler, { Resource } from 'devextreme-react/scheduler';

import { gqlClient } from 'gql-client';
import AppointEditor from './AppointEditor';
import { Popup } from 'devextreme-react';
import { v4 as uuid_v4 } from 'uuid';
import dayjs from 'dayjs';
import Appointment from './Appointment';
import { writeAppointment } from './scheduler-export';
import { PageContent } from 'components';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

const dsScheduler_gql = loader('./dsScheduler.gql');
const removeMessages = loader('../../contexts/messages/removeMessages.gql');

const views = [ 'day', 'week' ];
export const sources = [
  { id: 'lab', text: 'Лабораторія', color: '#727bd2' },
  { id: 'wid', text: 'Віджет', color: '#ad8603' },
  { id: 'ringo', text: 'Ringo', color: '#188b2a' },
];
const OtkScheduler = () => {
  const startOfDay = dayjs().startOf('date').toDate();
  const [ currentDate, setCurrenDate ] = useState(startOfDay);
  const [ timeline, setTimeLine ] = useState([]);
  const [ popupVisible, setPopupVisible ] = useState(false);
  const [ appointmentData, setAppointmentData ] = useState({});
  const [ currentView, setCurrentView ] = useState('day');

  const shedulerRef = useRef();

  const loadSchedulerData = useCallback(() => {
    gqlClient
      .query({
        query: dsScheduler_gql,
        variables: {
          jfilt: [
            {
              fld: 'date',
              expr: '>=',
              val: currentDate,
            },
            { c: 'and' },
            {
              fld: 'date',
              expr: '<',
              val: dayjs(currentDate).add(1, 'week').toDate(),
            },
          ],
        },
      })
      .then(({ data }) => {
        const tLine = data.list.map((el) => ({
          ...el,
          startDate: new Date(el.startDate),
          endDate: new Date(el.endDate),
          partner: (el.partner === null) ? { ref: '', id: '' } : el.partner,
          buyers_order: el?.buyers_order,
          color: el?.color,
        }));
        setTimeLine(tLine);
      });
  },[currentDate]);
  //dsScheduler
  useEffect(() => {
    loadSchedulerData();
  }, [currentDate, currentView, loadSchedulerData]);

  const modifyAppointment = (appointment) => {
    const data = appointment.appointmentData;
    writeAppointment(data).then(() => loadSchedulerData());
  };

  return (
    <>
      <Scheduler
        ref={shedulerRef}
        timeZone='Europe/Kiev'
        dataSource={timeline}
        firstDayOfWeek={1}
        showAllDayPanel={false}
        views={views}
        currentView={currentView}
        height={600} // width={480}
        cellDuration={30}
        currentDate={currentDate}
        appointmentComponent={Appointment}
        onAppointmentAdded={modifyAppointment}
        onAppointmentUpdated={modifyAppointment}
        onAppointmentDeleted={(delApp) => {
          delApp.appointmentData._deleted = true;
          modifyAppointment(delApp);
        }}
        onOptionChanged={({ name, value }) => {
          if (name === 'currentDate') setCurrenDate(value);
          if (name === 'currentView') setCurrentView(value);
        }}
        startDayHour={7}
        endDayHour={21}
        onAppointmentFormOpening={(data) => {
          data.cancel = true;
          setAppointmentData(data.appointmentData);
          setPopupVisible(true);
        }}>
        <Resource
          dataSource={sources}
          fieldExpr='source'
          label='Джерело'
          useColorAsDefault={true}
        />
      </Scheduler>
      {popupVisible && <Popup
        visible={popupVisible}
        width={500}
        height={'auto'}
        closeOnOutsideClick
        showCloseButton
        onHiding={() => setPopupVisible(false)}>
        <AppointEditor
          data={appointmentData}
          updateData={(uData) => {
            if (uData?._id) {
              shedulerRef.current.instance.updateAppointment(uData);
            } else {
              uData._id = `shed.appoint|${uuid_v4()}`;
              shedulerRef.current.instance.addAppointment(uData);
            }
            setPopupVisible(false);
          }}
        />
      </Popup>}
    </>
  );
};

export const ShedulerPage = ( ) => {

  const [ clearNewMessages ] = useMutation(removeMessages);

  useEffect(() => {
    clearNewMessages({ variables: { class_name: 'shed.appoint' }});
  }, [clearNewMessages]);

  return <PageContent size='large'>
      <div className='otk-page-header'>Розклад роботи</div>
    <OtkScheduler/>
  </PageContent>;
};

export default OtkScheduler;
