import React from 'react';
import PropTypes from 'prop-types';
import { API_HOST } from 'app-constants';

const qr_code_url = `${API_HOST}/barcode?bcid=qrcode&text=`;

export const QRCodePaymentForm = ({ data }) => {
  const url = `${qr_code_url}${data.policy_payment?.forwardUrl ??
    data?.forwardUrl}`;
  return (
    <div style={{ display: 'flex', height: 'auto', margin: '0 auto',
       width: '100%', justifyContent: 'center' }}>
      <img src={url} width={200} height={200} alt=''/>
    </div>
  );
};

QRCodePaymentForm.propTypes = {
  data: PropTypes.object.isRequired,
};

export default QRCodePaymentForm;
