import React, { useState, createContext, useContext, useCallback } from 'react';
import { defaultGlobalOptions, DEFAULT_AVATAR_URL } from 'app-constants.js';
import { signIn as sendSignInRequest, loadOptions, getInit, logout } from 'api/auth.js';
import { gqlClient, userVar } from 'gql-client.js';
import { gql } from 'graphql.macro';
import { setLoginTimeStamp } from 'hooks/useFirstLoginedView.js';
import { Projects } from 'datasources/dsProj/ds-proj.js';
import { initapi } from 'pages/insurance-policy/api/services.js';

const AuthProvider = (props) => {
  const [ user, setUser ] = useState();
  const [ loading, setLoading ] = useState(true);
  // const gqlClient = useApolloClient();
  const [ options, setOptions ] = useState({ global: {} });
  const [ lab, setLab ] = useState({});
  const [ projects, setProj ] = useState();

  const getOptions = useCallback(async (branch) => {
    const result = await loadOptions(branch);
    setOptions({ ...defaultGlobalOptions, ...result });
    return result;
  }, []);

  const loadInit = useCallback(async (user) => {
    const init = await getInit(user);
    const { options, lab, proj } = init;
    initapi(options.assurance);
    setOptions({ ...defaultGlobalOptions, ...options });
    setLab(lab);
    setProj(new Projects(proj, lab));
  }, []);

  const signIn = useCallback(async (email, password) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      await loadInit(result.data);
      setUser(result.data);
      userVar(result.data);
    }
    setLoading(false);
    return result;
  }, [loadInit]);

  const reAuth = useCallback(async () => {
    const result = await gqlClient.query({
      query: gql`query re_auth{re_auth}`,
      errorPolicy: 'none' })
      .then((response) => response.data.re_auth || { isOk: false },
      )
      .then((result) => ({
        isOk: result.isOk ?? true,
        message: result.isOk ? '' : 'Помилка входу',
        data: {
          ...result,
          avatarUrl: result.avatarUrl || DEFAULT_AVATAR_URL,
        },
      }))
      .catch(() => ({
        isOk: false,
        message: 'Помилка входу',
      }));

    if (result.isOk) {
      setUser(result.data);
      userVar(result.data);
      await loadInit(result.data);
      setLoginTimeStamp();
    }
    setLoading(false);
    return result;
  }, [loadInit]);

  const signOut = useCallback(async () => {
    await logout();
    gqlClient.stop();
    await gqlClient.resetStore();
    setUser(undefined);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, reAuth,
        loading, options, lab, projects, getOptions, setOptions }}
      {...props}
    />
  );
};

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
