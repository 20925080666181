 
import { useDocChangeListener, usePayKinds } from 'hooks';
import React, {
  Suspense,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { gqlClient } from 'gql-client';
import { loader } from 'graphql.macro';
import {
  Button,
  CheckBox,
  Form,
  LoadIndicator,
  LoadPanel,
  NumberBox,
  SelectBox,
  TextBox,
  ValidationGroup,
  Validator,
} from 'devextreme-react';
import {
  ColCountByScreen,
  GroupItem,
  Label,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form';
import { TextArea } from 'devextreme-react/text-area';
import { custom as customDialog } from 'devextreme/ui/dialog';

import {
  DocHeader,
  DocInfoBar,
  DocMenu,
  PartnerInfoPanel,
  PartnerSearch,
  StickyBar,
} from 'components';
import { Order, badActReasons } from '../../models/documents/order/order';
import { useAuth } from 'contexts/auth';
import { reducer } from './reducerOrderV2';

import styles from './order.module.scss';
import {
  API_HOST,
  FORM_STYLING_MODE,
  orderDocStatuses,
  SertTerminalPartner,
} from 'app-constants';
import { SelectProject } from 'components/projects/selectProject';
import { ServiceGrid } from './compnents/serviceGrid/serviceGrid';
import { DocContext } from 'contexts/docContext';
import { getMstoValue, selectOnEdit } from 'utils/helpers';
import { docRowValidationMsg, docValidationMsg, messages } from 'messages';
import PaymentForm from 'components/payment-form/payment-form';
import ContractInfoPopup from './compnents/contractPopup';
import { chain } from 'lodash';
import { showError, showSuccess, showWarning } from 'utils/notify';
import { normalizePhoneNumber } from 'utils/phone-number';
import { dsDebitsByAct } from 'datasources';
import { AskPolicy } from 'components/ask-policie/ask-policie';
import { CustomRule } from 'devextreme-react/validator';
import { ProcessTag, StatusTag, ReadOnlyTag } from './compnents/tags.jsx';


const checkContractQuery = loader('./gql/checkContracts.gql');
const createActfromOrder = loader('./gql/createAct.graphql');

const DOC_CLOSE_PERIOD = 0;
const jurValue = 'ЮрЛицо';

const closeDate = dayjs().subtract(DOC_CLOSE_PERIOD, 'day').startOf('day');
const getDefVisibility = (options) => options?.buyers_order?.fieldsByProject
  .find((el) => el.proj === 'default') || {};

// АктНесоответствия
const badactDocType = 'АктНесоответствия';

export const OrderV2 = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const { user, projects, lab, options } = auth;
  // const { proj } = useProj();

  const { payKindByRef, availablePayKinds, payKindByName } = usePayKinds();
  const [ state, dispatch ] = useReducer(reducer, {
    doc: new Order(id, auth),
    loading: id !== 'new',
    price: lab.price,
    visibles: getDefVisibility(options),
    contractPopupVisible: false,
    showPaymentForm: false,
    loaderActButton: false,
    debit: 0,
  });
  
  const formGroupRef = useRef();
  const partnerInfoPanelRef = useRef();
  const skipRefresh = useRef(false);

  /**
   * @type {Order}
   */
  const doc = state.doc;
  const mstoValue = getMstoValue(doc.date, options);

  const pay_kinds = availablePayKinds(lab, { disableT300: doc.ext_json?.doc_type === badactDocType });
  // console.log(pay_kinds, lab, doc.ext_json?.doc_type);

  useDocChangeListener('doc.buyers_order', () => {
    if (skipRefresh.current) {
      skipRefresh.current = false;
    } else {
      doc.load().then(() => {
        dispatch({ type: 'UPDATE_DOC', payload: {} });
        showWarning('Документ був оновлений з бази даних', 5000);
      });
    }
  }, doc.ref);



  const checkSubContract = async () => {
    const {partner, dateTform,  proj } = state.doc;
    if (!partner || !lab) return
    gqlClient
      .query({
        query: checkContractQuery,
        variables: {
          partner: partner.ref,
          date: dateTform,
          proj: proj,
        },
      })
      .then((response) => {
        if (response?.data?.checkContract) {
          const myDialog = customDialog({
            title: 'Знайдено Генральну угоду.',
            messageHtml: `
              <p style="text-align:center;">
              <b>За умовами Генеральної угоди ви виконуєте роботи <br/>
              по субпідряду для вказаного контрагента.<br/> 
              Внесення номеру автомобіля в замовленні є Обов'язковим.<br/>
              <p style="text-align:center;color:red;"> У звіті лабораторії 
              вказувати суми як у замовленні!!! </p>
              </b></p>`,
            buttons: [
              { text: 'Продовжити', onClick: () => 1 },
              { text: 'Без використання субпідряду', onClick: () => 2, disabled: true },
              // ...
            ],
          });
          return myDialog.show().then((dialogResult) => {
            const isSubContract = dialogResult === 1;
            if (isSubContract) {
              const checkContract = response?.data?.checkContract;
              const internalContract = checkContract.cont;
              const baseContract = checkContract.contb;
              const individualPrice = checkContract.individual_price;
              let contractPrice = internalContract.price_type;
              const displayPrice = individualPrice || baseContract.price_type;

              if (lab.organization === baseContract.organization) {
                contractPrice = displayPrice;
              }
              state.doc.changePriceType(displayPrice).then(() => {
                dispatch({
                  type: 'UPDATE_DOC',
                  payload: {
                    contract: internalContract,
                    isSubContract,
                    price_type: contractPrice,
                  },
                });
              });
            }
          });
        }
        return {};
      });
  };


  const validateForm = () => {
    const validatorResult = formGroupRef.current.instance.validate();
    let errorMessage = '';
    const quantTot = doc.totalQty;
    const amountTot = doc.totalAmount;
    const { partUUID, partValue, teminalUUID, terminalLimit } = options.easyPay;

    validatorResult?.brokenRules?.forEach((rule) => {
      errorMessage += docValidationMsg(rule.message);
    });

    if (doc.date < closeDate) {
      errorMessage += docValidationMsg(' Дата документу недозволена');
    }

    if (!doc.partner.ref) {
      errorMessage += docValidationMsg(messages.PARTNER_REQUIRED);
    }

    if (doc.pay_kind === partUUID) {
      if (quantTot * partValue > terminalLimit) {
        errorMessage += docValidationMsg(
          ` Кількість операцій для типу оплати Т300 перевищує ліміт ${terminalLimit} грн.`,
        );
      }

      if (projects.refById('ekmt') === doc.proj) {
        errorMessage += docValidationMsg(' Тип оплати Т300 недопустимий для ЄКМТ');
      }
    }
    if (doc.services.length === 0) {
      errorMessage += docValidationMsg(' Таблична частина порожня');
    }

    if (
      doc.pay_kind === teminalUUID && amountTot > (terminalLimit || 4999)
    ) {
      errorMessage += docValidationMsg(
        ` Сума замовлення з типом платежу "Термінал" перевищує ліміт ${terminalLimit} грн.`,
      );
    }
    doc.services.forEach((r) => {
      if (!r.nom?.ref) errorMessage += docRowValidationMsg(messages.NOM_IS_EMPTY, r.row);
      if (!r.price) errorMessage += docRowValidationMsg(messages.NOM_NO_PRICE, r.row);
      if (r.quantity <= 0) errorMessage += docRowValidationMsg('Невірна кількість', r.row);

      const nats = r.nats - (r.msto ? mstoValue : 0);
      if (nats > 0.5 * r.price) errorMessage += docRowValidationMsg('Націнка більше 50% цiни', r.row);

      if (doc.isSubContract && !r.gos_code) errorMessage += docRowValidationMsg('Номер автомобіля обов\'язковий', r.row);
    });
    return errorMessage;
  };

  const handleDocumentSave = async () => {
    const err = validateForm();
    if (err) return Promise.reject(err);
    if (!doc.isNew && !doc.number_doc) {
      return Promise.reject(`Документ не записано.
      Дочекайтесь присвоєнняння номеру документа.`);
    }
    if (doc.to1C) {
      return Promise.reject(`Документ на обробці.
      Дочекайтесь завершення і повторіть пізніше.`);
    }

    skipRefresh.current = true;
    doc.to1C = true;
    doc.ext_json.saveByLab = dayjs();

    return doc.save();
  };

  const handleCreateAct = async () => {
    if (state.loaderActButton) return;
    dispatch({ type: 'ACTLOADER', payload: true });

    const response = await gqlClient.mutate({
      mutation: createActfromOrder,
      variables: { buyers_order: doc.ref },
    });

    dispatch({ type: 'ACTLOADER', payload: false });
    const { error, errors, act } = response.data.createActfromOrder;
    if (error || errors) {
      showError('Помилка створення акту');
      return;
    }
    showSuccess(`Створено: ${act}`);
    dispatch({ type: 'UPDATE_DOC', payload: { shipped: doc.totalAmount } });
  };

  const updateVisibility = () => {
    const pvis = options?.buyers_order?.fieldsByProject.find((el) => el.proj === doc.proj);
    const rvis = pvis || getDefVisibility(options);
    dispatch({ type: 'UPDATE', payload: { visibles: rvis } });
  };

  const updateDebit = () => {
    const partnerRef = state.doc?.partner?.ref;
    dsDebitsByAct.userData = {
      partner: partnerRef,
    };

    dsDebitsByAct.load().then((res) => {
      const deb = res?.data?.reduce((prev, curr) => prev + curr.amount, 0);
      dispatch({ type: 'DEBIT', payload: deb });
    });
  };

  const fillFrom = async (source, doc_type) => {
    if (doc_type === 'badact') {
      const sourceDoc = await new Order(source, auth).load(source);
      dispatch({ type: 'UPDATE_DOC', payload: {
        partner: sourceDoc.partner,
        ClientPersonPhone: sourceDoc?.ext_json?.phone ?? '',
        proj: sourceDoc.proj,
        isSubContract: sourceDoc.isSubContract,
        contract: sourceDoc.contract,
        price_type: sourceDoc.price_type,
        badact_reason: badActReasons[0],
        ext_json: {
          phone: sourceDoc.ext_json?.phone,
          doc_type: badactDocType,
          doc_source: source,
        },
      } });
      if (sourceDoc.isSubContract) checkSubContract();
    }
  };

  useEffect(() => {
    if (id === 'new') {
      state.doc = new Order(id, auth);
      const search = new URLSearchParams(location.search);
      const source = search.get('source') ?? undefined;
      const doc_type = search.get('doc_type') ?? undefined;
      if (source && doc_type) fillFrom(source, doc_type);
    } else {
      dispatch({ type: 'LOADING', payload: true });
      doc.load(id)
        .then(() => {
          // if (doc.isSubContract) checkSubContract();
          dispatch({ type: 'UPDATE_DOC', payload: {} });
          dispatch({ type: 'LOADING', payload: false });
          updateVisibility();
          updateDebit();
        })
        .catch(() => {
          dispatch({ type: 'LOADING', payload: false });
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ id ]);

  useEffect(() => {
    const labPrice = payKindByRef(doc.pay_kind)?.pay_form === 'Терминал' ? doc._lab.price_type_rp : doc._lab.price_type;
    const useprice = doc.price_type ?? labPrice;
    doc.changePriceType(useprice).then(() => checkSubContract());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ doc.pay_kind, doc.partner ]);

  const handleProjchange = (e) => {
    dispatch({ type: 'UPDATE_DOC', payload: { proj: e } });
    updateVisibility();
    checkSertAndTerminal(doc.pay_kind, e);
  };

  const checkSertAndTerminal = (pay_kind, proj) => {
    if (pay_kind === payKindByName.terminal.ref && (proj === projects.sert || proj === projects.gbo)) {
      dispatch({ type: 'UPDATE_PARTNER', payload: SertTerminalPartner });
    }
  };

  const paymentData = (doc) => (doc.number_doc
    ? {
      amount: doc.totalAmount,
      description: `Oплата за послуги згідно рахунку ${doc.number_doc} у т.ч. ПДВ`,
      id: doc.number_doc,
      data: { _id: doc._id },
      proj: doc.proj,
      organization: doc.organization?.ref,
      doc,
    } : null);

  const availablePayMethods = () => chain(lab?.pay_systems?.filter((ps) => ps.proj === doc.proj))
    .map('resource').uniq().value() ?? [];

  const paymentEnabled = doc.totalAmount && doc.organization?.ref && doc._status === orderDocStatuses.ACCEPTED &&
        availablePayMethods().length > 0;

  const docTypeCaption = () => (doc.ext_json?.doc_type === badactDocType ? ' (акт невідповідності)' : '');

  const captionName = `Замовлення${docTypeCaption()}`;

  const phoneValidator = ({ value }) => {
    const phoneValue = normalizePhoneNumber(value.padStart(12, '380'));
    const zeroPhone = phoneValue?.endsWith('000000000');
    return (phoneValue?.length === 12 && !zeroPhone);
  };

  return (
    <div>
      <StickyBar>
        <DocInfoBar
          name={captionName}
          data={doc}
          loading={state.loading}
          isNew={doc.isNew}>
          {doc.protected && <ReadOnlyTag/> }
          {doc.to1C && <ProcessTag/>}
          <StatusTag status={doc._status}/>
        </DocInfoBar>

        <DocMenu
          isDocNew={doc.isNew}
          allowSaving={!doc.isReadOnly}
          onSave={handleDocumentSave}
          printItems={useMemo(
            () => [
              {
                text: 'Рахунок',
                url: `${API_HOST}/printform/${id}/inv`,
                disabled: !doc.number_doc,
              },
              {
                text: 'Договір',
                url: `${API_HOST}/printform/${id}/dog`,
                disabled: !doc.number_doc,
              },
              {
                text: 'Договір сертифікації',
                url: `${API_HOST}/printform/${id}/dogs`,
              },
              {
                text: 'Договір для Казначейства',
                url: `${API_HOST}/printform/${id}/dogk`,
                disabled: !doc.number_doc,
              },
              {
                text: 'Замовлення-термінал',
                disabled: doc.isNew,
                onClick: () => import('./compnents/print-order-info')
                  .then((func) => func.openOrderInfo(doc, projects))
              },
            ],
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [ doc, doc.number_doc, id, projects ],
          )}
          actionItems={
             [ {
                text: 'Акт невідповідності',
                disabled: !doc.number_doc,
                onClick: () => navigate(`/order/new?source=${id}&doc_type=badact`),
              } ]
            }
        />
      </StickyBar>
      {state.showPaymentForm &&
        <Suspense fallback={<LoadPanel visible={true} />}>
          <PaymentForm
            paymentData={paymentData(doc)}
            description={`Замовлення послуг: ${ doc.services.map((service) => service.nom.name).join(', ')}`}
            visible={state.showPaymentForm}
            availableMethods={availablePayMethods()}
            onClose={() => dispatch({ type: 'UPDATE', payload: { showPaymentForm: false } })}
          />
        </Suspense>
      }

      {state.contractPopupVisible &&
        <Suspense fallback={<LoadPanel visible={true} />}>
          <ContractInfoPopup
            doc={doc}
            visible={state.contractPopupVisible}
            onClose={() => dispatch({ type: 'UPDATE', payload: { contractPopupVisible: false } })}
          />
        </Suspense>
      }

      <ValidationGroup ref={formGroupRef}>
        <div className='content-block otk-content-block'>
          <div className='otk-doc-container otk-doc-form otk-doc-form-large dx-card'>
            <Form labelLocation='left' formData={doc}>
              <GroupItem>
                <ColCountByScreen xs={1} sm={2} md={2} lg={2} />

                <GroupItem colSpan={1}>
                  <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
                  <SimpleItem colSpan={2} isRequired>
                    {/* <Label text='Контрагент' /> */}
                    <div className={styles.partnerContainer}>
                      <PartnerSearch
                        partner={doc.partner}
                        readOnly={doc.isReadOnly}
                        label='Контрагент'
                        stylingMode={FORM_STYLING_MODE}
                        onSelect={async (e) => {
                          if (e.ref !== doc.partner.ref && doc.shipped &&
                            doc.partner.individual_legal === jurValue) {
                            showError('Акт виписано. Зміна контрагента заборонена', 5000);
                            return;
                          }
                          dispatch({ type: 'UPDATE_PARTNER', payload: e });
                          doc.changePriceType(doc._lab.price_type).then(() => checkSubContract());
                          updateDebit();
                        }}
                        validator={ <Validator><RequiredRule /></Validator> }
                      />
                      {doc.partner?.isCorporate && 
                        <>
                          <Button
                            className={styles.partnerInfoButton}
                            visible={true}
                            icon='file'
                            hint='договори'
                            onClick={() => partnerInfoPanelRef.current.instance.show()}
                          />
                          <PartnerInfoPanel ref={partnerInfoPanelRef} partner={doc.partner} />
                        </> }
                    </div>
                  </SimpleItem>
                  <SimpleItem colSpan={2}>
                    <TextBox
                      id='ClientPerson'
                      value={doc.ClientPerson}
                      hint='контактана особа'
                      label='Контактана особа'
                      onChange={({ event }) => dispatch({ type: 'UPDATE_DOC', payload: { ClientPerson: event.target.value } })}
                      stylingMode={FORM_STYLING_MODE}
                      readOnly={doc.isReadOnly}
                    />
                  </SimpleItem>
                  <GroupItem colSpan={2}>
                    <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
                    <SimpleItem colSpan={1}>
                      <Label text='Телефон' />
                      <TextBox
                        id='ClientPersonPhone'
                        value={doc.ext_json.phone ?? doc.ClientPersonPhone}
                        hint='номер телефону'
                        onChange={({ event }) => {
                          const normPhone = normalizePhoneNumber(event.target.value);
                          dispatch({ type: 'UPDATE_DOC', payload: { ClientPersonPhone: normPhone, ext_json: { phone: normPhone } } });
                        }}
                        stylingMode={FORM_STYLING_MODE}
                        readOnly={doc.isReadOnly}
                        mask={'+38\\0 (00) 000 0000'}
                        useMaskedValue={true}
                      >
                        <Validator>
                          <CustomRule validationCallback={phoneValidator} message="Некоректний номер телефону"/>
                          <RequiredRule message='Номер телефону не вказаний'/>
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}
                      cssClass={styles.addPriceItem}
                      visible={doc._canUseAddPrice && state.visibiles?.addPrice?.visible}
                    >
                      <Label text='Додатковий прайс' location='left' />
                      <CheckBox
                        id='useAddPrice'
                        readOnly={doc.isReadOnly || doc.isSubContract}
                        onValueChange={(value) => {
                          let price_type = doc?.data?.price_type ?? lab.price_type;
                          if (!doc.pay_kind) {
                            showError('Не вказано тип оплати', 1000);
                            return;
                          }
                          if (payKindByRef(doc.pay_kind).pay_form === 'Терминал') price_type = user.price_type_cash ?? price_type;
                          if (value && user.alter_price_type) price_type = user.alter_price_type;
                          if (price_type === doc.price_type) {
                            dispatch({ type: 'ALTER_PRICE', payload: value });
                          } else {
                            doc.services.forEach((r) => {
                               r.discount_percent_automatic = 0;
                            });
                            doc.changePriceType(price_type)
                              .then(() => {
                                doc.price_type = price_type;
                                dispatch({ type: 'ALTER_PRICE', payload: value });
                              });
                          }
                        }}
                        value={doc.useAddPrice}
                      />
                    </SimpleItem>
                    {state.debit !== 0 && <SimpleItem colSpan={1}>
                      <Label text='Борг:'/>
                      <Link to={`/debitsbyact_report/${doc?.partner?.ref || ''}`}>
                        <TextBox id='debitBox' value={state.debit} stylingMode={FORM_STYLING_MODE} readOnly={doc.isReadOnly}/>
                      </Link>
                    </SimpleItem>
                    }
                  </GroupItem>
                  { doc.ext_json?.doc_type === badactDocType && <SimpleItem colSpan={2}>
                    <Label text='Безкоштовно тому що:' />
                    <SelectBox
                      stylingMode={FORM_STYLING_MODE}
                      items={badActReasons}
                      value={doc.badact_reason}
                      onValueChange ={(e) => dispatch({ type: 'UPDATE_DOC', payload: { badact_reason: e } })}
                    />
                  </SimpleItem> }
                  {doc.subDocNumber && doc.isSubContract && <SimpleItem colSpan={2}>
                    <Label text='Основний:' />
                    <TextBox
                      stylingMode={FORM_STYLING_MODE} readOnly={true}
                      value={doc.subDocNumber}
                      // onValueChange ={(e) => dispatch({ type: 'UPDATE_DOC', payload: { badact_reason: e } })}
                    />
                  </SimpleItem>
                  }
                </GroupItem>

                <GroupItem>
                  <SimpleItem>
                    <DocHeader number_doc={doc.number_doc} date={doc.date} statusText={doc._status.text} />
                  </SimpleItem>
                  <SimpleItem colSpan={2} label={{ location: 'left' }}>
                    <Label text='Тип' />
                    <SelectProject
                      readOnly={doc.isReadOnly}
                      items={projects.available}
                      value={doc.proj || projects.default.ref}
                      onValueChange={handleProjchange}
                      stylingMode={FORM_STYLING_MODE}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={2}>
                    <SelectBox
                      stylingMode={FORM_STYLING_MODE}
                      items={pay_kinds}
                      displayExpr='name'
                      valueExpr='ref'
                      placeholder=''
                      labelMode='static'
                      label='Тип платежу'
                      value={doc.pay_kind}
                      onValueChange ={(e) => {
                        // disable T300 from 09.08.2023
                        // if (e === '1e6950dc-747c-11ec-8ad3-00155d000b0b') {
                        //   showWarning('Онлайн оплата - тимчасово не використовується', 3000);
                        //   return;
                        // }
                        const payKind = payKindByRef(e);
                        let price_type = doc.price_type;

                        if (!doc.isSubContract) {
                          price_type = doc?.data?.price_type ?? lab.price_type;
                          if (payKind.pay_form === 'Терминал') price_type = user.price_type_cash ?? price_type;
                          if (doc.useAddPrice && user.alter_price_type) price_type = user.alter_price_type;
                        }

                        if (payKind.pay_form === 'Терминал' && (doc.proj === projects.sert || doc.proj === projects.gbo)) {
                            checkSertAndTerminal(payKind.ref, doc.proj);
                        }

                        if (price_type === doc.price_type) {
                          dispatch({ type: 'UPDATE_DOC', payload: { pay_kind: e } });
                        } else {
                          doc.changePriceType(price_type)
                            .then(() => dispatch({ type: 'UPDATE_DOC', payload: { pay_kind: e, price_type } }));
                        }
                      }}
                      buttons={[
                        { name: 'dropDown', location: 'before' },
                        { name: 'pay', location: 'after',
                          options: {
                            text: 'Оплатити',
                            icon: 'money',
                            stylingMode: 'text',
                            visible: payKindByName.online?.ref === doc.pay_kind && (doc?._paymentData?.amount ?? 0) === 0,
                            disabled: !paymentEnabled,
                            onClick: () => dispatch({ type: 'UPDATE', payload: { showPaymentForm: true } }),
                          },
                        },
                        { name: 'payed', location: 'after',
                          options: {
                            text: 'Сплачено',
                            elementAttr: { class: styles.payedButton },
                            icon: 'check',
                            stylingMode: 'text',
                            visible: payKindByName.online?.ref === doc.pay_kind && doc?._paymentData?.amount > 0,
                          },
                        },
                      ]}
                    >
                      <Validator>
                        <CustomRule
                          validationCallback={({ value }) => {
                            const { pay_form } = payKindByRef(value) ?? { pay_form: undefined };
                            return !(doc.partner.individual_legal === jurValue && pay_form === 'Терминал');
                          }}
                          message='Недопустимий тип платежу для юросіб'
                        />
                        <RequiredRule message='Тип платежу не вказаний'/>
                      </Validator>
                    </SelectBox>
                  </SimpleItem>
                  <GroupItem colCount={4} className={styles.infoLinksGroup}>
                    <SimpleItem
                      colSpan={2}
                      render={() => {
                        const { shipped, number_doc, ref } = doc;
                        if (!shipped && number_doc && state.visibles?.approveJob?.visible) {
                          return (
                            <span className={styles.otk_link_as_button} onClick={handleCreateAct}>
                              <LoadIndicator visible={state.loaderActButton} className={styles.loader} width={16} height={16}/>
                              Створити aкт
                            </span>
                          );
                        } else if (number_doc && shipped > 0) {
                          return (
                            <Link to={`/acts?trans_ref=${ref}`}>
                              <span className={styles.otk_link_as_button}>Акти</span>
                            </Link>
                          );
                        }
                        return <></>;
                      }}
                    />
                    <SimpleItem colSpan={1}>
                      <Link to=''
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch({ type: 'UPDATE', payload: { contractPopupVisible: true } });
                        }}>
                        <span className={styles.otk_link_as_button}> Інфо </span>
                      </Link>
                    </SimpleItem>
                  </GroupItem>
                </GroupItem>
              </GroupItem>
              <SimpleItem>
                <DocContext.Provider value={{ doc: state.doc, state }}>
                  <ServiceGrid dataSource = {doc.services}/>
                </DocContext.Provider>
              </SimpleItem>

              <GroupItem>
                <ColCountByScreen xs={1} sm={8} md={8} lg={10} />
                <SimpleItem colSpan={8}>
                  <Label text='Коментар' />
                  <TextArea
                    id='note'
                    readOnly={doc.isReadOnly}
                    value={doc.note}
                    stylingMode={FORM_STYLING_MODE}
                    hint='коментар'
                    onChange={(e) => dispatch({ type: 'UPDATE_DOC', payload: { note: e.event?.target?.value } })}
                  />
                </SimpleItem>
                {lab?.isRv && <SimpleItem colSpan={2}>
                  <Label text='РВ' />
                  <NumberBox
                    id='rvSum'
                    value = {doc.ext_json?.rvSum ?? 0}
                    format="#0.00"
                    onChange={(e) => {
                      const value = parseFloat(e.event?.target?.value.replace(',', '.'));
                      dispatch({ type: 'UPDATE_DOC', payload: { ext_json: { rvSum: value } } });
                    }}
                    rtlEnabled
                    onFocusIn={selectOnEdit}
                    stylingMode={FORM_STYLING_MODE}
                    readOnly={doc.isReadOnly}
                  />
                </SimpleItem>}
                <SimpleItem colSpan={6} colCount={2}>
                  <AskPolicy
                    isPolicie = {doc?.ext_json?.isPolicie ?? ''}
                    onChangeIsPolicie={(value) => {
                      dispatch({
                        type: 'UPDATE_DOC',
                        payload: { ext_json: { isPolicie: value } },
                      });
                    }}
                    policieValidTo = {doc.ext_json?.policieValidTo}
                    onChangeValid={(value) => {
                      dispatch({
                        type: 'UPDATE_DOC',
                        payload: { ext_json: { policieValidTo: dayjs(value.value).format('YYYY-MM-DD') } },
                      });
                    }}
                  />
                </SimpleItem>
              </GroupItem>
            </Form>
          </div>
        </div>
      </ValidationGroup>
    </div>
  );
};
