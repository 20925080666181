import { gql, useSubscription } from '@apollo/client';
import { useAuth } from 'contexts/auth';
import { useEffect } from 'react';

const DOC_SUBCRIPTION = gql(
  `subscription OnDocChanged($input: JSONObject, $className: String, $ref: String) {
    docChange(input: $input, class_name: $className, ref: $ref)
  }`,
);

export const useDocChangeListener = (className, onChange, ref) => {
  const { user } = useAuth();
  const { data, loading } = useSubscription(DOC_SUBCRIPTION, {
    variables: {
      input: { username: user?.name || '' },
      className,
      ref,
    },
  });

  useEffect(() => {
    if (!loading && (!ref || data?.docChange?.ref === ref)) {
      onChange(data?.docChange);
    }
  }, [ data ]);
};
