import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDate } from 'hooks';
import { useApolloClient } from '@apollo/client';
import { reservePolicy, savePolicyData } from './utils';
//components
import { FormField } from 'components/form-field/form-field';
import { TextLine } from 'pages/ep-main/components/text-line';
import {
  LoadIndicator,
  TextBox,
  TextArea,
  Button,
  DateBox,
  SelectBox,
  ValidationSummary,
  ValidationGroup,
  CheckBox,
} from 'devextreme-react';

import Validator, {
  RequiredRule,
  PatternRule,
  StringLengthRule,
} from 'devextreme-react/validator';

import { AutoShortInfo } from './autoShortInfo';
import { pText, pNum, pEmail, pDocumentRecord } from 'moks/patterns';
import { DX_DATE_DISPLAY_FORMAT } from 'app-constants';

import styles from '../styles/style.module.scss';

const required = 'обов`язкове поле';

export const Part3 = (props) => {
  const gqlClient = useApolloClient();
  const { setData = () => {}, data = {} } = props;
  const { companie = {} } = data;
  const groupRef = useRef();
  const { today, formatDate } = useDate();
  const [ loading, setLoading ] = useState(false);

  //перевірка даних форми, резервування полісу
  const handleReservePolicy = async () => {
    const validate = groupRef?.current?.instance?.validate();
    if (validate?.status === 'valid') {
      setLoading(true);
      await reservePolicy({ setData, data });
      setLoading(false);
    } else {
      console.log('No valid');
    }
  };
  //збереження даних переход на наступний крок
  const handleSavePolicy = async (e) => {
    e.preventDefault();
    setLoading(true);
    await savePolicyData({ gqlClient, data });
    setLoading(false);
    setData((prev) => ({ ...prev, step: 4 }));
  };

  //збереження даних з полів
  const handleValueChanged = (e) => {
    const value = e?.event?.target?.value || e?.value;
    setData((prev) => ({
      ...prev,
      [e?.element?.id]: value }));
  };
  //збереження даних для полів з датами
  const handleDateChanged = (e) => {
    setData((prev) => ({
      ...prev,
      [e?.element?.id]: formatDate(e?.value) }));
  };
  return (<>
    <h3>Крок 3. Ввід даних автовласника</h3>
    <ValidationGroup ref={groupRef}>
      <AutoShortInfo data={data} />
      <div>
        <img src={companie?.image?.path} alt={'logo'} />
      </div>
      <FormField textWidth={'300px'} text={'Найменування СК: '}>
        <span>{companie?.fullname}</span>
      </FormField>
      <br />
      <FormField textWidth={'300px'} text={'Розмір франшизи: '}>
        <span>{companie?.selectedFranchise}</span>
      </FormField>
      {/* <FormField textWidth={'300px'} text={'Додаткове описання полісу: '}>
        <div style={{ maxWidth: 500 }}
          dangerouslySetInnerHTML={ { __html: companie?.description } }></div>
      </FormField> */}
      <FormField textWidth={'300px'} text={'Сума: '}>
        <span className={styles?.sum}>{`${companie?.selectedPrice} грн.`}</span>
      </FormField>
      <TextLine text=
        {''}/>
      <br />

      <FormField textWidth={'300px'} text={'Дата початку дії полісу: '}>
        <DateBox
          value={data?.start_date || data?.policy_start_date}
          id="policy_start_date"
          type="date"
          displayFormat={DX_DATE_DISPLAY_FORMAT}
          useMaskBehavior={true}
          stylingMode={'outlined'}
          onValueChanged={handleDateChanged}
          hint={'Дата початку дії'}
          min={today.add(1, 'day')}
          width={'200px'}
          readOnly={!!data.start_date}
        >
          <Validator>
            <RequiredRule
              message={`Дата початку дії полісу - ${required}`}
            />
          </Validator>
        </DateBox>
      </FormField>
      {!!data.start_date &&
        <FormField textWidth={'300px'}
          text={'(дата обрана на попередньому кроці)'}>
          {''}</FormField>}
      <TextLine text=
        {'дані власника'}/>
      <br />
      <div className={styles?.row_2x}>
        <div>
          <FormField textWidth={'170px'} text={'Прізвище: '}>
            <TextBox
              id={'lastname'}
              value={data?.lastname }
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
            >
              <Validator>
                <RequiredRule
                  message=
                    {`Прізвище - ${required}`}
                />
                <StringLengthRule
                  ignoreEmptyValue={true}
                  min={2}
                  max={100}
                  message='2-100 символів'
                />
                <PatternRule
                  message='тільки літери'
                  pattern={pText}
                />
              </Validator>
            </TextBox>
          </FormField>
          <br />
          <FormField textWidth={'170px'} text={'Ім’я: '}>
            <TextBox
              id={'firstname'}
              value={data?.firstname}
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
            >
              <Validator>
                <RequiredRule
                  message=
                    {`Ім’я - ${required}`}
                />
                <StringLengthRule
                  ignoreEmptyValue={true}
                  min={2}
                  max={100}
                  message={'2-100 символів'}
                />
                <PatternRule
                  message='тільки літери'
                  pattern={pText}
                />
              </Validator>
            </TextBox>
          </FormField>
          <br />
          <FormField textWidth={'170px'} text={'По Батькові: '}>
            <TextBox
              id={'middlename'}
              value={data?.middlename}
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
            >
              <Validator>
                <StringLengthRule
                  ignoreEmptyValue={true}
                  min={2}
                  max={100}
                  message={'2-100 символів'}
                />
                <PatternRule
                  message='тільки літери'
                  pattern={pText}
                />
              </Validator>
            </TextBox>
          </FormField>
          <br />
          <FormField textWidth={'170px'} text={'Дата народження: '}>
            <DateBox
              id="birth_date"
              value={data?.birth_date}
              type="date"
              displayFormat={DX_DATE_DISPLAY_FORMAT}
              useMaskBehavior={true}
              stylingMode={'outlined'}
              onValueChanged={handleDateChanged}
              hint={'Дата народження'}
              max={today.subtract(18, 'year')}
              width={'200px'}
            >
              <Validator>
                <RequiredRule
                  message={`Дата народження - ${required}`}
                />
              </Validator>
            </DateBox>
          </FormField>
          <br />
          <FormField textWidth={'170px'} text={'ІПН: '}>
            <TextBox
              id={'egrpou'}
              value={data?.egrpou}
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
            >
              <Validator>
                <RequiredRule
                  message=
                    {`ІПН - ${required}`}
                />
                <PatternRule
                  message='тільки цифри'
                  pattern={pNum}
                />
                <StringLengthRule
                  ignoreEmptyValue={true}
                  min={10}
                  max={10}
                  message={'10 символів'}
                />
              </Validator>
            </TextBox>
          </FormField>
          <br />
          <FormField textWidth={'170px'} text={'E-Mail: '}>
            <TextBox
              id={'email'}
              value={data?.email}
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
            >
              <Validator>
                <RequiredRule
                  message=
                    {`E-Mail - ${required}`}
                />
                <PatternRule
                  message='хибний формат'
                  pattern={pEmail}
                />
              </Validator>
            </TextBox>
          </FormField>
          <br />
          <FormField textWidth={'170px'} text={'Телефон: '}>
            <TextBox
              id={'phone'}
              value={data?.phone}
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
              mask={'+38\\0(00)000-0000'}
              maskChar={'X'}
              useMaskedValue={true}
            >
              <Validator>
                <RequiredRule
                  message=
                    {`Телефон - ${required}`}
                />
              </Validator>
            </TextBox>
          </FormField>
        </div>
        <br />
        <div>
          <FormField textWidth={'170px'} text={'Область: '}>
            <TextBox
              id={'region'}
              value={data?.region }
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
            >
              <Validator>
                <RequiredRule
                  message=
                    {`Область - ${required}`}
                />
                <StringLengthRule
                  ignoreEmptyValue={true}
                  min={2}
                  max={100}
                  message={'2-225 символів'}
                />
              </Validator>
            </TextBox>
          </FormField>
          <br />
          <FormField textWidth={'170px'} text={'Місто: '}>
            <TextBox
              id={'city'}
              value={data?.city}
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
            >
              <Validator>
                <RequiredRule
                  message=
                    {`Місто - ${required}`}
                />
                <StringLengthRule
                  ignoreEmptyValue={true}
                  min={2}
                  max={100}
                  message={'2-225 символів'}
                />
              </Validator>
            </TextBox>
          </FormField>
          <br />
          <FormField textWidth={'170px'} text={'Вулиця: '}>
            <TextBox
              id={'street'}
              value={data?.street}
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
            >
              <Validator>
                <RequiredRule
                  message=
                    {`Вулиця  - ${required}`}
                />
                <StringLengthRule
                  ignoreEmptyValue={true}
                  min={2}
                  max={100}
                  message='2-225 символів'
                />
              </Validator>
            </TextBox>
          </FormField>
          <br />
          <FormField textWidth={'170px'} text={'Будинок: '}>
            <TextBox
              id={'house'}
              value={data?.house}
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
            >
              <Validator>
                <RequiredRule
                  message=
                    {`Будинок  - ${required}`}
                />
                <StringLengthRule
                  ignoreEmptyValue={true}
                  min={1}
                  max={100}
                  message={'1-225 символів'}
                />
              </Validator>
            </TextBox>
          </FormField>
          <br />
          <FormField textWidth={'170px'} text={'Квартира: '}>
            <TextBox
              id={'flat'}
              value={data?.flat}
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
            >
              <Validator>
                <StringLengthRule
                  ignoreEmptyValue={true}
                  min={1}
                  max={100}
                  message={'1-225 символів'}
                />
              </Validator>
            </TextBox>
          </FormField>
          <br />
        </div>
        <div>
          <FormField textWidth={'170px'} text={'Документ: '}>
            <SelectBox
              id={'document_type'}
              value={data?.document_type || null}
              items={
                data?.documents[data?.privilege] ||
            [ { name: 'passport', code: 'passport' },
              { name: 'id_card', code: 'id_card' },
              { name: 'посвідчення водія', code: 'driver_license' },
              { name: 'war_participant', code: 'war_participant' },
              { name: 'invalid', code: 'invalid' },
              { name: 'chernobyl_victim', code: 'chernobyl_victim' },
              { name: 'pensioner', code: 'pensioner' } ]}
              onValueChanged={handleValueChanged}
              displayExpr="name"
              valueExpr="code"
              wrapItemText={true}
              stylingMode={'outlined'}
              width={'200px'}
            >
              <Validator>
                <RequiredRule
                  message=
                    {`Документ - ${required}`}
                />
              </Validator>
            </SelectBox>
          </FormField>
          <br />
          {data?.document_type !== 'id_card' &&
      <>
        <FormField textWidth={'170px'} text={'Серія документа: '}>
          <TextBox
            id={'document_series'}
            value={data?.document_series}
            stylingMode={'outlined'}
            onValueChanged={handleValueChanged}
            width={'200px'}
          >
            <Validator>
              <RequiredRule
                message=
                  {`Серія документа - ${required}`}
              />
            </Validator>
          </TextBox>
        </FormField>
        <br />
      </>
          }
          <FormField textWidth={'170px'} text={'Номер  документа: '}>
            <TextBox
              id={'document_number'}
              value={data?.document_number}
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
            >
              <Validator>
                <RequiredRule
                  message=
                    {`Номер  документа - ${required}`}
                />
                <StringLengthRule
                  ignoreEmptyValue={true}
                  min={1}
                  max={20}
                  message={'1-20 символів'}
                />
                <PatternRule
                  message='тільки цифри'
                  pattern={pNum}
                />
              </Validator>
            </TextBox>
          </FormField>
          <br />
          {data?.document_type === 'id_card' &&
        <>
          <FormField textWidth={'170px'} text={'Запис документа: '}>
            <TextBox
              id={'document_record'}
              value={data?.document_record}
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
            >
              <Validator>
                <RequiredRule
                  message=
                    {`Запис документа - ${required}`}
                />
                <PatternRule
                  message='не вірний формат (^[0-9]{8}-[0-9]{5}$)'
                  pattern={pDocumentRecord}
                />
              </Validator>
            </TextBox>
          </FormField>
          <br />
        </>
          }
          <FormField textWidth={'170px'} text={'Ким виданий: '}>
            <TextArea
              id={'document_issued'}
              value={data?.document_issued}
              stylingMode={'outlined'}
              onValueChanged={handleValueChanged}
              width={'200px'}
              height={'85px'}
            >
              <Validator>
                <RequiredRule
                  message=
                    {`Ким виданий - ${required}`}
                />
              </Validator>
            </TextArea>
          </FormField>
          <br />
          <FormField textWidth={'170px'} text={'Дата видачі: '}>
            <DateBox
              id="document_issue_date"
              value={data?.document_issue_date}
              type="date"
              displayFormat={DX_DATE_DISPLAY_FORMAT}
              useMaskBehavior={true}
              stylingMode={'outlined'}
              onValueChanged={handleDateChanged}
              hint={'Дата видачі'}
              max={today}
              width={'200px'}
            >
              <Validator>
                <RequiredRule
                  message={`Дата видачі - ${required}`}
                />
              </Validator>
            </DateBox>
          </FormField>
        </div>
      </div>
      <br />
      <ValidationSummary id="summary"/>
      {data?.policy_reserve && data?.policy_verify &&
      <>
        <TextLine />
        <FormField textWidth={'300px'} text={'Зарезервовано: '}>
          <span>{`Дата/час: ${data?.policy_verify?.otp_verified_at}`}</span>
          <br />
          <span>{`Статус: ${data?.policy_verify?.policy_status}`}</span>
          <br />
          <span>{`Номер полісу: ${data?.policy_verify?.policy_number}`}</span>
          <br />
          {/* посилання на поліс в базі МТСБУ */}
          <a href={data?.policy_reserve?.policy_direct_link}
            rel="noreferrer" target='_blank'>
            policy_direct_link
          </a>
          <br />
          {data?.policy_payment?.forwardUrl &&
            <a href="/#"
              rel="noreferrer" target='_blank'
              onClick={handleSavePolicy}>
            Оплатити
            </a>}
        </FormField>
        <TextLine />
      </>}
      <FormField textWidth={'300px'} text={' '}>
        <div>
          <CheckBox
            id={'confirm_personal_data'}
            value={data?.confirm_personal_data || false}
            onValueChanged={handleValueChanged}
            text="Підтверджую правильність введених даних"
          />
          <br />
        </div>
        <div className={styles?.res_title}>
          <Button className={styles?.mr_40}
            onClick={() => setData((prev) => ({ ...prev, step: 2 }))}>
            {'< Назад'}
          </Button>
          <Button onClick={handleReservePolicy}
            disabled={!data?.confirm_personal_data || loading}
            className={styles?.mr_60}>Резервування</Button>
          {loading &&
          <LoadIndicator
            className={styles?.res_loader}
            indicatorSrc='img/loader.gif'
            width={50}
            height={50}
          />
          }
        </div>
      </FormField>
      <br />
    </ValidationGroup>
  </>);
};

Part3.propTypes = {
  setData: PropTypes.func,
  data: PropTypes.object,
};

export default Part3;
