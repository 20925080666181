import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

const getProj = loader('./getProj.gql');

export const useProj = () => {
  const { data } = useQuery(getProj, { fetchPolicy: 'cache-first' });

  const isProjectEqualById = (id, ref) => {
    const fProj = data.getProj.find((r) => r.id === id);
    return fProj?.ref === ref;
  };

  return {
    proj: data?.getProj,
    isProjectEqualById,
  };
};
