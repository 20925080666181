import notify from 'devextreme/ui/notify';

export const showError = (message) => {
  notify({ message, position: { at: 'center' } }, 'error', 5000);
};
export const showWarning = (message, displayTime = 4000) => {
  notify({ message, position: { at: 'center' } }, 'warning', displayTime);
};
export const showSuccess = (message) => {
  notify({ message, position: { at: 'center' } }, 'success', 3000);
};
